import React from 'react';
import { Link, graphql } from 'gatsby';

import '../fonts/fonts-post.css';
// import Bio from '../components/Bio';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
// import Signup from '../components/Signup';
import Panel from '../components/Panel';
import { formatPostDate, formatReadingTime } from '../utils/helpers';
import { rhythm, scale } from '../utils/typography';
import { codeToLanguage, createLanguageLink, loadFontsForCode } from '../utils/i18n';

const systemFont = `system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif`;

class Translations extends React.Component {
  render() {
    let { translations, lang, languageLink, editUrl } = this.props;

    return (
      <div className="translations">
        <Panel style={{ fontFamily: systemFont }}>
          {translations.length > 0 && (
            <span>
              <span>Translated into: </span>
              {translations.map((l, i) => (
                <React.Fragment key={l}>
                  {l === lang ? <b>{codeToLanguage(l)}</b> : <Link to={languageLink(l)}>{codeToLanguage(l)}</Link>}
                  {i === translations.length - 1 ? '' : ' • '}
                </React.Fragment>
              ))}
            </span>
          )}
          {lang !== 'en' && lang !== 'ru' && (
            <>
              <br />
              <br />
              <Link to={languageLink('en')}>Read the original</Link>
              {' • '}
              <a href={editUrl} target="_blank" rel="noopener noreferrer">
                Improve this translation
              </a>
              {' • '}
              <Link to={`/${lang}`}>View all translated posts</Link>{' '}
            </>
          )}
        </Panel>
      </div>
    );
  }
}

class BlogPostTemplate extends React.Component {
  render() {
    const post = this.props.data.markdownRemark;
    const siteMetadata = this.props.data.site.siteMetadata;
    const { title, github, siteUrl } = siteMetadata;

    let { previous, next, slug, translations, translatedLinks } = this.props.pageContext;
    const lang = post.fields.langKey;

    // Replace original links with translated when available.
    let html = post.html;
    translatedLinks.forEach(link => {
      // jeez
      function escapeRegExp(str) {
        return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
      }
      let translatedLink = '/' + lang + link;
      html = html.replace(new RegExp('"' + escapeRegExp(link) + '"', 'g'), '"' + translatedLink + '"');
    });

    translations = translations.slice();
    translations.sort((a, b) => {
      return codeToLanguage(a) < codeToLanguage(b) ? -1 : 1;
    });

    loadFontsForCode(lang);
    // TODO: this curried function is annoying
    const languageLink = createLanguageLink(slug, lang);
    const enSlug = languageLink('en');
    const editUrl = `https://github.com/${github.userName}/${github.repoName}/edit/master/src/pages/${enSlug.slice(1, enSlug.length - 1)}/index${
      lang === 'en' ? '' : '.' + lang
    }.md`;
    const discussUrl = `https://mobile.twitter.com/search?q=${encodeURIComponent(`${siteUrl}${enSlug}`)}`;

    return (
      <Layout location={this.props.location} title={title}>
        <SEO lang={lang} title={post.frontmatter.title} description={post.frontmatter.spoiler} slug={post.fields.slug} />
        <main>
          <article>
            <header>
              <h1 style={{ color: 'var(--textTitle)' }}>{post.frontmatter.title}</h1>
              <p
                style={{
                  ...scale(-1 / 5),
                  display: 'block',
                  marginBottom: rhythm(1),
                  marginTop: rhythm(-4 / 5),
                }}
              >
                {/*{formatPostDate(post.frontmatter.date, lang)}*/}
                {`${formatReadingTime(post.timeToRead)}`}
              </p>
              {translations.length > 0 && <Translations translations={translations} editUrl={editUrl} languageLink={languageLink} lang={lang} />}
            </header>
            <div dangerouslySetInnerHTML={{ __html: html }} />
            <footer>
              <p>
                <a href={discussUrl} target="_blank" rel="noopener noreferrer">
                  Discuss on Twitter
                </a>
                {` • `}
                <a href={editUrl} target="_blank" rel="noopener noreferrer">
                  Edit on GitHub
                </a>
              </p>
            </footer>
          </article>
        </main>
        <aside>
          {/*<div*/}
          {/*  style={{*/}
          {/*    margin: '90px 0 40px 0',*/}
          {/*    fontFamily: systemFont,*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Signup />*/}
          {/*</div>*/}
          {/*<h3*/}
          {/*  style={{*/}
          {/*    fontFamily: 'Montserrat, sans-serif',*/}
          {/*    marginTop: rhythm(0.25),*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <Link*/}
          {/*    style={{*/}
          {/*      boxShadow: 'none',*/}
          {/*      textDecoration: 'none',*/}
          {/*      color: 'var(--color)',*/}
          {/*    }}*/}
          {/*    to={'/'}*/}
          {/*  >*/}
          {/*    {title}*/}
          {/*  </Link>*/}
          {/*</h3>*/}
          {/*<Bio />*/}
          <nav>
            <ul
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                listStyle: 'none',
                padding: 0,
              }}
            >
              <li>
                {previous && (
                  <Link to={previous.fields.slug} rel="prev">
                    ← {previous.frontmatter.title}
                  </Link>
                )}
              </li>
              <li>
                {next && (
                  <Link to={next.fields.slug} rel="next">
                    {next.frontmatter.title} →
                  </Link>
                )}
              </li>
            </ul>
          </nav>
        </aside>
      </Layout>
    );
  }
}

export default BlogPostTemplate;

/*
frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        spoiler
      }
 */

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
        siteUrl
        author {
          name
        }
        github {
          userName
          repoName
        }
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      html
      timeToRead
      frontmatter {
        title
        spoiler
      }
      fields {
        slug
        langKey
      }
    }
  }
`;
